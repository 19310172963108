class Tabs {
  constructor() {
    this.tabs = document.querySelectorAll('.js-tabs li');
    this.contents = document.querySelectorAll('.js-tab-content');
  }
  init() {
    if (this.tabs.length > 0) {
      this.bindEvents();
      window.addEventListener('hashchange', () => this.loadContentFromHash(), false);
      window.addEventListener('DOMContentLoaded', () => this.loadContentFromHash(), false);
    }
  }
  bindEvents() {
    this.tabs.forEach((tab) => {
      tab.addEventListener('click', (e) => {
        e.currentTarget.classList.add('is-active');
        const targetId = e.currentTarget.dataset.target;

        this.tabs.forEach((tab) => {
          if (tab !== e.currentTarget) {
            tab.classList.remove('is-active');
          }
        });
        
        this.contents.forEach((content) => {
          if (content.id == targetId) {
            content.classList.add('is-active');
          } else {
            content.classList.remove('is-active');
          }
        });
      });
    });
  }

  loadContentFromHash() {
    const hash = window.location.hash.substring(1); // Entferne das '#'
    if (hash) {
      const targetTab = Array.from(this.tabs).find(tab => tab.dataset.target === hash);
      if (targetTab) {
        targetTab.click(); // Simuliere einen Klick auf den Tab
      }
    }
  }
}

export default new Tabs();

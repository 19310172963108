class StickyAreaObserver {
  constructor() {
    this.topBorder = document.querySelector('.js-sticky-area-top-border');
    this.bottomBorder = document.querySelector('.js-sticky-area-bottom-border');
    this.stickyNavbar = document.querySelector('.js-sticky-navbar');
    this.stickyFooter = document.querySelector('.js-sticky-footer');
  }

  init() {
    if (this.topBorder || this.bottomBorder) {
      this.runStickyAreaObserver();
    }
  }

  runStickyAreaObserver() {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.target.classList.contains('js-sticky-area-top-border')) {
          if (entry.isIntersecting) {
            this.stickyNavbar.classList.remove('is-sticky');
          } else {
            this.stickyNavbar.classList.add('is-sticky');
          }
        } else if (
          entry.target.classList.contains('js-sticky-area-bottom-border')
        ) {
          if (entry.isIntersecting) {
            this.stickyFooter.classList.remove('is-sticky');
          } else {
            this.stickyFooter.classList.add('is-sticky');
          }
        }
      });
    });
    if (this.topBorder) {
      observer.observe(this.topBorder);
    }
    if (this.bottomBorder && this.stickyFooter) {
      observer.observe(this.bottomBorder);
    }
  }
}

export default new StickyAreaObserver();

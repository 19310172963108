<script setup>
import {useVuelidate} from '@vuelidate/core'
import {required, email, sameAs, minLength, maxLength} from '@vuelidate/validators'
import {reactive} from '../../../node_modules/vue/dist/vue.esm-bundler';
import {ref, onMounted, computed, watchEffect} from "vue";

const props = defineProps({
  alternateText: String,
  topic: String,
  formtype: String
})

const state = reactive({
  emailSent: false,
  alternateSubject: '',
  isPrivacyChecked: false,
  form: {
    contractNumber: '',
    name: '',
    birthday: '',
    email: '',
    emailConfirmed: '',
  }
});

const form = ref(null);

function isDate(value) {
  const date = new Date(value);
  return !isNaN(date.getTime());
}

const rules = {
  isPrivacyChecked: {required, sameAs: sameAs(true), $autoDirty: true},
  form: {
    contractNumber: {required, minLength: minLength(10), maxLength: maxLength(10), $autoDirty: true},
    name: {required, $autoDirty: true},
    birthday: {required, isDate, $autoDirty: true},
    email: {required, email, $autoDirty: true},
    emailConfirmed: {required, email, sameAs: sameAs(computed(()=> state.form.email)), $autoDirty: true},
  }
};

const v$ = useVuelidate(rules, state)

const submit = async () => {
  const isFormCorrect = await v$.value.$validate();

  const formData = new FormData(form.value);
  formData.append('formtype', props.formtype)

  if (isFormCorrect) {
   
    fetch('/form-submit', {
      body: formData,
      method: 'POST'
    })
    .then(response => response.json())
    .then(data => {
      state.emailSent = true;
    })
    .catch(error => {
      state.emailSent = true;
    }); 

  } 
}

</script>

<template>
  <div class="columns ">
    <div class="column is-8 text-flow">
      <h2 is="aktualisierung-email-adresse" class="heading-2">Aktualisierung E-Mail Adresse</h2>
    </div>
  </div>
  <form action="" class="text-flow" ref="form" v-if="!state.emailSent">
    <div class="box">
      <div class="columns is-multiline">
        <div class="column is-9">
          <div class="field has-floating-label" :class="{ 'is-active': state.form.contractNumber.length > 0 }">
            <label for="form.contractNumber" class="label">Vertragsnummer (10-stellig, beginnend mit 6 z.B 6300000000) *</label>
            <div class="control">
              <input id="form.contractNumber" class="input" :class="{ 'is-danger' : v$.form.contractNumber.$errors.length > 0 }" type="text" name="contractNumber" placeholder="Vertragsnummer (10-stellig, beginnend mit 6 z.B 6300000000) *"
                     v-model="state.form.contractNumber"  @blur="v$.form.contractNumber.$touch">
            </div>
            <p v-if="v$.form.contractNumber.$errors.length > 0" class="help is-danger"><i class="fas fa-warning"></i> Bitte überprüfen Sie ihre Eingabe.</p>
          </div>

          <div class="field has-floating-label" :class="{ 'is-active': state.form.name.length > 0 }">
            <label for="form.name" class="label">NACHNAME, Vorname (lt. Vertrag) *</label>
            <div class="control">
              <input id="form.name" class="input" :class="{ 'is-danger' : v$.form.name.$errors.length > 0 }" type="text" name="name" placeholder="NACHNAME, Vorname (lt. Vertrag) *"
                     v-model="state.form.name" @blur="v$.form.name.$touch">
            </div>
            <p v-if="v$.form.name.$errors.length > 0" class="help is-danger"><i class="fas fa-warning"></i> Bitte überprüfen Sie ihre Eingabe.</p>
          </div>

          <div class="field has-floating-label is-active">
            <label for="form.birthday" class="label">Geburtsdatum</label>
            <div class="control">
              <input id="form.birthday" class="input" :class="{ 'is-danger' : v$.form.birthday.$errors.length > 0 }" type="date" name="birthday" placeholder="Geburtstag"
                     v-model="state.form.birthday" @blur="v$.form.birthday.$touch">
            </div>
            <p v-if="v$.form.birthday.$errors.length > 0" class="help is-danger"><i class="fas fa-warning"></i> Bitte überprüfen Sie ihre Eingabe.</p>
          </div>

          <div class="field has-floating-label" :class="{ 'is-active': state.form.email.length > 0 }">
            <label for="form.email" class="label">Aktuelle E-Mail Adresse *</label>
            <div class="control">
              <input id="form.email" class="input" :class="{ 'is-danger' : v$.form.email.$errors.length > 0 }" type="email" name="email" placeholder="Aktuelle E-Mail Adresse *"
                     v-model="state.form.email" @blur="v$.form.email.$touch">
            </div>
            <p v-if="v$.form.email.$errors.length > 0" class="help is-danger"><i class="fas fa-warning"></i> Bitte überprüfen Sie ihre Eingabe.</p>
          </div>

          <div class="field has-floating-label" :class="{ 'is-active': state.form.emailConfirmed.length > 0 }">
            <label for="form.emailConfirmed" class="label">Aktuelle E-Mail Adresse bestätigen *</label>
            <div class="control">
              <input id="form.emailConfirmed" class="input" :class="{ 'is-danger' : v$.form.emailConfirmed.$errors.length > 0 }" type="email" name="emailConfirmed" placeholder="Aktuelle E-Mail Adresse bestätigen *"
                     v-model="state.form.emailConfirmed" @blur="v$.form.emailConfirmed.$touch">
            </div>
            <p v-if="v$.form.emailConfirmed.$errors.length > 0" class="help is-danger"><i class="fas fa-warning"></i>E-Mail Adressen stimmen nicht überein.</p>
          </div>

          <div class="field mt-5">
            <div class="control">
              <label class="checkbox">
                <input type="checkbox" name="attach-file" v-model="state.isPrivacyChecked">
                Ich habe die <a target="_blank" href="/datenschutzinformationen">Datenschutzinformation</a> zur Kenntnis genommen.
              </label>
            </div>
            <p v-if="v$.isPrivacyChecked.$errors.length > 0" class="help is-danger">Bitte überprüfen Sie ihre Eingabe.</p>
          </div>

          <div class="mt-5 mb-5">
            <p><i class="far fa-lightbulb"></i> <strong>Hinweis</strong>: Die Übertragung der Daten an uns erfolgt in
              verschlüsselter Form.
              Dennoch möchten wir Sie bitten, uns keine zusätzlichen persönlichen Daten mit Ihrer Anfrage zu
              übermitteln.</p>
          </div>

          <div class="buttons is-left mt-6">
            <a @click.prevent="submit" class="button is-primary">Absenden</a>
          </div>
        </div>
      </div>
    </div>
  </form>
  <div v-else>
    <div class="columns">
      <div class="column text-flow">
        <h2 class="heading-2">Vielen Dank</h2>
        <p>Ihre Nachricht ist bei uns eingegangen und wird von uns schnellstmöglich bearbeitet. Üblicherweise erhalten Sie innerhalb von zwei Werktagen Rückmeldung. Bitte beachten Sie, dass Anfragen, die uns am Wochenende oder an Feiertagen erreichen, eine entsprechend längere Bearbeitungszeit aufweisen.</p>
        <p><strong>Hinweis:</strong> In der aktuellen Lage kann es immer noch zu einer erhöhten Anzahl von Anfragen im Kundenservice kommen. Daher bitten wir um Verständnis, falls unsere Rückmeldung Sie nicht innerhalb der üblichen Fristen erreicht.</p>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>

import 'fslightbox';
import {createApp} from '../../node_modules/vue/dist/vue.esm-bundler';

import Accordion from './modules/Accordion';
import NotificationBox from './modules/NotificationBox';
import Modal from './modules/Modal';
import stickAreaObserver from './modules/StickyAreaObserver';
import mobileNavigation from './modules/MobileNavigation';
import nfxSwiper from './modules/NfxSwiper';
import tabs from './modules/Tabs';
import InsuranceTable from './modules/InsuranceTable';

import ContactForm from './components/ContactForm.vue';
import UpdateEmailForm from './components/UpdateEmailForm.vue';
import ComplaintForm from './components/ComplaintForm.vue';
import UnsubscribeForm from './components/UnsubscribeForm.vue';
import ContactInfoBox from './components/ContactInfoBox.vue';

Accordion.init();
NotificationBox.init();
Modal.init();
stickAreaObserver.init();
mobileNavigation.init();
nfxSwiper.init();
tabs.init();
InsuranceTable.init();

const contactFormElement = document.querySelector('.vue-contact-form');
if (contactFormElement) {
    const contactFormApp = createApp({});
    contactFormApp.component('contact-form', ContactForm);
    contactFormApp.mount('.vue-contact-form');
}

const emailFormElement = document.querySelector('.vue-email-form');
if (emailFormElement) {
    const emailFormApp = createApp({});
    emailFormApp.component('email-form', UpdateEmailForm);
    emailFormApp.mount('.vue-email-form');
}

const complaintFormElement = document.querySelector('.vue-complaint-form');
if (complaintFormElement) {
    const complaintFormApp = createApp({});
    complaintFormApp.component('complaint-form',ComplaintForm);
    complaintFormApp.mount('.vue-complaint-form');
}

const unsubscribeFormElement = document.querySelector('.vue-unsubscribe-form');
if (unsubscribeFormElement) {
    const unsubscribeFormApp = createApp({});
    unsubscribeFormApp.component('unsubscribe-form',UnsubscribeForm);
    unsubscribeFormApp.mount('.vue-unsubscribe-form');
}
/* 
const contactInfoBoxElement = document.querySelector('.vue-contact-info-box');
if (contactInfoBoxElement) {
    const contactInfoBoxApp = createApp({});
    contactInfoBoxApp.component('contact-info-box', ContactInfoBox);
    contactInfoBoxApp.mount('.vue-contact-info-box');
} */

const cookieSettingsLink = document.evaluate("//a[contains(text(), 'Cookie-Einstellungen')]", document, null, XPathResult.ANY_TYPE, null ).iterateNext();
if (cookieSettingsLink) {
    cookieSettingsLink.addEventListener('click', (event) => {
        event.preventDefault()
        UC_UI.showSecondLayer();
    });
}

const NotificationBox = {
    init() {
        const notificationBox = document.querySelector('.notification-box');

        if (notificationBox) {
            notificationBox.querySelector('.modal-close').addEventListener('click', () => {
                notificationBox.classList.remove('is-active');
            });

            setTimeout(() => {
                notificationBox.classList.add('is-active');
            }, 1000);

            setTimeout(() => {
                notificationBox.classList.remove('is-active');
            }, 20000);
        }
    }
};

export default NotificationBox;

class Modal {
    constructor() {
        this.activeModal = null;
    }

    init() {
        this.bindEvents();
    }

    bindEvents() {
        this.html = document.querySelector('html');

        document.addEventListener('click', this.handleClick.bind(this));
    }

    handleClick(event) {
        const element = event.target;

        if (element.classList.contains('modal-close')) {
            this.close();
        }

        if (this.activeModal && !this.activeModal.querySelector('.modal-content').contains(element)) {
            this.close();
        }

        if (element.dataset.hasOwnProperty('modalOpen')) {
            this.open(element);
        }
    }

    open(element) {
        const target = document.querySelector(element.dataset.modalOpen);

        if (target) {
            this.html.classList.add('is-clipped');
            target.classList.add('is-active');

            this.activeModal = target;
        }
    }

    close() {
        const target = document.querySelector('.modal.is-active');

        if (target) {
            target.classList.add('is-closing');
            setTimeout(() => {
                this.html.classList.remove('is-clipped');
                target.classList.remove('is-active');
                target.classList.remove('is-closing');
            }, 400);

            this.activeModal = null;
        }
    }
}

export default new Modal();

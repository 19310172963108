//Swiper
import Swiper from 'swiper';
import { Navigation, Autoplay, Pagination } from 'swiper/modules';

class NfxSwiper {
  constructor() {
    this.sliderTabs = Array.from(document.querySelectorAll('.js-swiper-tab'));
    this.slider = null;
  }

  init() {
    if (this.sliderTabs.length > 0) {
      this.initSlider();
      this.onSlideChange();
      this.addTabEventListener(this.sliderTabs);
    }
  }

  initSlider() {
    this.slider = new Swiper('.js-slider', {
      modules: [Navigation, Pagination, Autoplay],
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true,
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      loop: false,
      autoplay: false,
      allowTouchMove: true,
    });
  }

  onSlideChange() {
    this.slider.on('slideChange', () => {
      this.switchActiveState(this.sliderTabs, this.slider.activeIndex);
    });
  }

  switchActiveState(nodeList, index) {
    nodeList.forEach((node) => {
      if (parseInt(node.dataset.target) === index) {
        node.classList.add('is-active');
      } else {
        node.classList.remove('is-active');
      }
    });
  }

  addTabEventListener(nodelist) {
    nodelist.forEach((node) => {
      node.addEventListener('click', (e) => {
        const index = parseInt(e.currentTarget.dataset.target);
        this.slider.slideTo(index, 400, true);
        this.switchActiveState(this.sliderTabs, index);
      });
    });
  }
}

export default new NfxSwiper();

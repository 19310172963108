<script setup>
  import {required} from '@vuelidate/validators'
  import {reactive} from '../../../node_modules/vue/dist/vue.esm-bundler';
  import {ref} from "vue";

  const props = defineProps({
    brands: Array,
  })

  const state = reactive({
    isExistingCustomer: null,
  });

  const contactInfoBox = ref(null);
  const selected = ref('');
  let disabled = ref(true);
  const brands = ref(props.brands);

  const rules = {
    isExistingCustomer: {required, $autoDirty: true},
  };

  const brandChange = (event) => {
    const val = event.target.value;
    const btn = document.querySelector('.js-brand-search-submit');

    if (val.length > 0) {
      disabled.value = false;
      btn.href = val;
    } else {
      disabled.value = true;
      btn.href = '';
    }
  };
</script>
<template>
  <div class="box">
    <div class="columns is-multiline">
      <div class="column">
        <div class="field is-horizontal">
          <div class="field-label">
            <label class="label">Sind Sie bereits Kunde?</label>
          </div>
          <div class="field-body">
            <div class="control">
              <label class="radio">
                <input type="radio" name="existing-customer" :value="true" v-model="state.isExistingCustomer">
                Ja
              </label>
              <label class="radio">
                <input type="radio" name="existing-customer" :value="false" v-model="state.isExistingCustomer">
                Nein
              </label>
            </div>
          </div>
        </div>

        <div v-if="state.isExistingCustomer === true" class="text-flow">
          <hr class="divider-line is-color-line">
          <h1 class="heading-1">Kennen Sie schon unser Online-Kundencenter?</h1>
          <div class="columns">
            <div class="column is-4">
              <h2 class="heading-2">
                <strong>Online-Kundencenter –</strong>
                <br>
                <span class="has-dark-text">unabhängig von Servicezeiten mit MyFinance</span></h2>
            </div>
            <div class="column is-8">
              <ul class="fa-ul text-flow">
                <li>
                  <span class="fa-li">
                    <icon class="fas fa-check"></icon>
                  </span>
                  <div>Einfache Online-Verwaltung Ihrer Verträge</div>
                </li>
                <li>
                  <span class="fa-li">
                    <icon class="fas fa-check"></icon>
                  </span>
                  <div>Vorlagen von A bis Z für Ihr Anliegen (Adressänderung bis Zulassungsbescheinigung) anfordern</div>
                </li>
                <li>
                  <span class="fa-li">
                    <icon class="fas fa-check"></icon>
                  </span>
                  <div>Verfolgen Sie online den Bearbeitungsstatus Ihrer Anfrage</div>
                </li>
                <li>
                  <span class="fa-li">
                    <icon class="fas fa-check"></icon>
                  </span>
                  <div>Schneller und direkter Kontakt zur Bank – senden Sie uns Anfragen rund um die Uhr</div>
                </li>
                <li>
                  <span class="fa-li">
                    <icon class="fas fa-check"></icon>
                  </span>
                  <div>Keine Wartezeiten am Telefon, kein Verbinden, keine unzustellbaren E-Mails</div>
                </li>
              </ul>
              <div class="columns one-column min-height-less mt-4">
                <div class="column  has-gap-24 is-align-items-center is-mobile-flex-column">
                  <div class="button-container "><a href="https://myfinance.stellantis-financial-services.de/" target="_blank" class="button is-primary">Login MyFinance</a></div>
                  <div class="button-container "><a href="https://myfinance.stellantis-financial-services.de/" class="is-link button is-primary">Jetzt registrieren</a></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="state.isExistingCustomer === false" class="text-flow">
          <h2 class="heading-2 mt-5">Ihr Stellantis Partner berät Sie gerne.</h2>
          <hr class="divider-line is-color-line">
          <ul class="fa-ul text-flow">
            <li>
                  <span class="fa-li">
                    <icon class="fas fa-check"></icon>
                  </span>
              <div>Sie interessieren sich für unsere <strong>Finanzierungs- und Leasingangebote</strong> und möchten sich nach unseren aktuellen Konditionen erkundigen?</div>
            </li>
            <li>
                  <span class="fa-li">
                    <icon class="fas fa-check"></icon>
                  </span>
              <div>Sie möchten gerne wissen, wie hoch die <strong>monatliche Rate für Ihr Traumauto</strong> ist bzw. ob ein Finanzierungs-/Leasingvertrag gemäß Ihrer aktuellen Lebenssituation möglich ist?</div>
            </li>
            <li>
                  <span class="fa-li">
                    <icon class="fas fa-check"></icon>
                  </span>
              <div>Sie haben Interesse an einer <strong>Stellantis Autoversicherung</strong>?</div>
            </li>
            <li>
                  <span class="fa-li">
                    <icon class="fas fa-check"></icon>
                  </span>
              <div>Sie möchten sich zu den <strong>Absicherungsmöglichkeiten im Zusammenhang mit einer Fahrzeugfinanzierung</strong> beraten lassen?</div>
            </li>
          </ul>
          <div class="mt-3 mb-4">Die kompetente Beratung zu unseren Leasing- und Finanzierungsangeboten erfolgt ausschließlich über den Stellantis Partner.</div>

          <div class="column is-5 pl-0" v-if="props.brands.length > 0">
            <div class="field has-addons">
              <div class="control is-expanded">
                <div class="select is-fullwidth">
                  <select name="brand-search" class="brand-search js-brand-search" v-model="selected" @change="brandChange($event)">
                    <option value="">Bitte auswählen ...</option>
                    <option v-for="item in brands" :value="item.value">{{ item.text }}</option>
                  </select>
                </div>
              </div>
              <div class="control">
                <a href="" target="_blank" class="button is-primary js-brand-search-submit" :class="{'disabled': disabled}">Händlersuche</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
const Accordion = {
    init() {
        const accordionContainer = document.querySelectorAll('[data-accordion]');
        const accordionNavigation = document.querySelector('.js-faq-navigation');
        const categoryAnchors = document.querySelectorAll('[data-category-anchor]');

        if (!accordionContainer) {
            return;
        }

        if (accordionNavigation) {
            accordionNavigation.addEventListener('click', function (event) {
                categoryAnchors.forEach(function (anchor) {
                    anchor.classList.remove('is-active');
                });
                const element = event.target;

                if (element.nodeName === 'A' && element.dataset.hasOwnProperty('categoryAnchor')) {
                    element.classList.add('is-active');
                    accordionContainer.forEach(function (container) {
                        if (container.id === element.getAttribute('href').replace('#', '')) {
                            container.classList.remove('is-hidden');
                        } else {
                            container.classList.add('is-hidden');
                        }
                    });
                }
            });
        }


        for (let container = 0; container < accordionContainer.length; container++) {
            accordionContainer[container].titles = accordionContainer[container].querySelectorAll('[data-accordion-title]');

            accordionContainer[container].addEventListener('click', function (event) {
                const element = event.target;

                if (!element.dataset.hasOwnProperty('accordionTitle')) {
                    return;
                }

                event.preventDefault();

                for (let title = 0; title < accordionContainer[container].titles.length; title++) {
                    if (accordionContainer[container].titles[title] !== element) {
                        accordionContainer[container].titles[title].classList.remove('is-active');
                    }
                }

                element.classList.toggle('is-active');
            });
        }

        if (document.location.hash.length > 0) {
            const hash = document.location.hash;

            if (hash.indexOf('/') === -1) {
                let element = document.querySelector('[href="' + decodeURIComponent(hash) + '"]');
                if (element) {
                  element.click();
                } else {
                  console.error('Element not found for hash: ' + decodeURIComponent(hash));
                }
            } else {
                let categoryHash = hash.split('/')[0];
                let questionHash = hash.split('/')[1];

                let categoryElement = document.querySelector('[href="' + decodeURIComponent(categoryHash) + '"]');
                let categoryContainer = document.querySelector('[id="' + decodeURIComponent(categoryHash.replace('#', '')) + '"]');
                categoryElement.click();
                let questionElement = categoryContainer.querySelector('[data-accordion-key="' + decodeURIComponent(questionHash) + '"]');
                questionElement.click();
            }
        }
    }
};

export default Accordion;

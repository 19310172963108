class MobileNavigation {
  constructor() {
    this.burger = document.querySelector('.js-navbar-burger');
    this.nav = document.querySelector('#' + this.burger.dataset.target);
    this.offcanvasClose = document.querySelector('.js-offcanvas-close');
  }
  init() {
    if (this.burger) {
      this.bindEvents();
    }
  }
  bindEvents() {
    this.burger.addEventListener('click', () => {
      /*  this.burger.classList.toggle('is-active'); */
      this.nav.classList.toggle('is-active');
    });

    this.offcanvasClose.addEventListener('click', () => {
      /* this.burger.classList.toggle('is-active'); */
      this.nav.classList.toggle('is-active');
    });
  }
}

export default new MobileNavigation();
